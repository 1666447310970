import { Router, useLocation } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";
import { createEffect, ErrorBoundary, onMount, Suspense } from "solid-js";
import { DottedLoader } from "./widgets/loader";
import "./app.css";
import { ModalProvider } from "~/components/modal";
import { HubbleError } from "~/components/error";
import { MetaProvider } from "@solidjs/meta";
import { isServer } from "solid-js/web";
import "solid-devtools";
import { captureErrorInSentry } from "~/utils/third_party/sentry";
import { config } from "~/data/config";
import { Toaster } from "solid-toast";
import { HttpHeader } from "@solidjs/start";

export default function HubbleRouter() {
  return (
    <Router
      root={(props) => (
        <>
          <ErrorBoundary
            fallback={(err) => {
              if (!isServer) {
                captureErrorInSentry(err);
              }

              if (config.env !== "Prod") {
                console.trace(err);
              }

              return <HubbleError errorMessage={err.message} />;
            }}
          >
            <ModalProvider>
              <MetaProvider>
                <SecurityHeaders />
                <Suspense fallback={<DottedLoader />}>
                  {props.children}
                </Suspense>
              </MetaProvider>
              <Toaster position="top-center" />
            </ModalProvider>
          </ErrorBoundary>
        </>
      )}
    >
      <FileRoutes />
    </Router>
  );
}

export function TopScroll() {
  const location = useLocation();

  onMount(async () => {
    await import("jquery")
      .then((module) => {
        return ((window as any).$ = module.default);
      })
      .catch((err) => {
        alert(err);
      });

    createEffect(() => {
      if (location.pathname) {
        $("html, body").animate({ scrollTop: 0 }, 0);
      }
    });
  });
  return <></>;
}

function SecurityHeaders() {
  return (
    <>
      <HttpHeader
        name="Content-Security-Policy"
        value="frame-ancestors 'self'"
      />
      {/* any domains for allowing iframes should be whitelisted in above header (csp) */}
      <HttpHeader name="X-Content-Type-Options" value="nosniff" />
    </>
  );
}
