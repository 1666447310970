import {
  cache,
  createAsync,
  useIsRouting,
  useParams,
  useSearchParams,
} from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  For,
  onMount,
  Show,
  Suspense,
} from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { BrandCard } from "~/components/brand/brand_card";
import {
  Header,
  isFreshLogin,
  setIsFreshLogin,
} from "~/components/brand_l2/header";
import { Footer } from "~/components/footer";
import {
  GroupTextHeaderOccasion,
  TrustMakerStrip,
} from "~/components/groups/content";
import { CategoryStrip } from "~/components/home/category_card";
import { RNREvent, rnrEventManager } from "~/data/events";
import {
  CategoryRouteData,
  getCategoryRouteData,
} from "~/server/data/category_route_data";
import {
  getCategoryKeyFromSlug,
  getCategoryTitleFromSlug,
  getClientIdFromCookie,
  getHighestPercentOff,
} from "~/utils/common";
import { BrandingRow, HubbleBranding } from "~/widgets/branding";
import { DottedLoader } from "~/widgets/loader";
import { Spacer } from "~/widgets/spacer";
import { zIndexes } from "~/z_indexes";
import occasionsData from "~/data/groups/occasions.json";
import recipientsData from "~/data/groups/recipients.json";
import attachPopStateListener from "~/utils/popstate_listener";
import { attachUserIdToSentry } from "~/utils/third_party/sentry";
import { Link, Meta, Title } from "@solidjs/meta";
import { Product } from "~/server/types/search";
import { getCoinsSummary } from "~/server/apis/client_apis";
import { config, RNR_PAGE_TITLE } from "~/data/config";

const getCategoryRouteData$C = cache(getCategoryRouteData, "category");

export const route = {
  load: async (context: any) => {
    try {
      return await getCategoryRouteData$C(
        getCategoryKeyFromSlug(context.params.category),
        {
          clientId: context.location.search.clientId,
          clientSecret: context.location.search.clientSecret,
          token: context.location.search.token,
        }
      );
    } catch (e) {
      throw e;
    }
  },
};

export default function CategoryPage() {
  const params = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const routeData: Accessor<CategoryRouteData | undefined> =
    createAsync<CategoryRouteData>(
      () => {
        return getCategoryRouteData$C(getCategoryKeyFromSlug(params.category), {
          clientId: queryParams.clientId,
          clientSecret: queryParams.clientSecret,
          token: queryParams.token,
        });
      },
      {
        deferStream: true,
      }
    );

  const [showCategoryList, setShowCategoryList] = createSignal(false);
  const [latestCoinsBalance, setLatestCoinsBalance] = createSignal<
    number | undefined
  >(undefined);
  const [refreshLatestBalance, setRefreshLatestBalance] = createSignal(false);

  onMount(() => {
    rnrEventManager.setClientId(getClientIdFromCookie() ?? "");
    rnrEventManager.setIdentity(routeData()?.userProfile?.id ?? "");
    rnrEventManager.sendEvent(RNREvent.VISIT_CATEGORY_PAGE, {
      categoryName: getCategoryTitleFromSlug(params.category),
    });
    attachUserIdToSentry(routeData()?.userProfile?.id ?? "");
    attachPopStateListener();
  });

  const isRouting = useIsRouting();

  const getHeaderTitleText = (products: Product[]) => {
    return `Enjoy up to ${getHighestPercentOff(products)}% off on ${getCategoryTitleFromSlug(params.category)}`;
  };

  const isLoggedIn = createMemo<boolean>(() => {
    return isFreshLogin() ?? false;
  });

  createEffect(() => {
    setIsFreshLogin(routeData()?.isLoggedIn ?? false);
  });

  async function fetchLatestBalance() {
    const response = await getCoinsSummary();
    setLatestCoinsBalance(response.totalAvailable);
  }

  createEffect(async () => {
    if (refreshLatestBalance()) {
      await fetchLatestBalance();
      setRefreshLatestBalance(false);
    }
  });

  createEffect(async () => {
    if (isLoggedIn()) {
      await fetchLatestBalance();
    }
  });

  const getCoins = createMemo<number>(() => {
    return (
      latestCoinsBalance() ?? routeData()?.coinsSummary?.totalAvailable ?? 0
    );
  });

  const isClientAxis = createMemo(() => {
    return routeData()?.clientId === config.axisClientId;
  });

  function getMetadataTitle(
    routeData: Accessor<CategoryRouteData | undefined>
  ) {
    const metadataTitle = routeData()?.metadata?.title;
    const fallbackTitle = getHeaderTitleText(
      routeData()?.brandList.data[0].products!
    );

    return metadataTitle && metadataTitle.trim()
      ? metadataTitle
      : fallbackTitle;
  }

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <div class="relative min-h-screen">
        <ClientOnlyComponent component={ClientComponent.ModalHost} />

        <Show when={routeData() && routeData()?.brandList}>
          <Meta name="robots" content="follow, index" />
          <Show
            when={routeData()?.mode === "rnr"}
            fallback={<Title>{getMetadataTitle(routeData)}</Title>}
          >
            <Title>{RNR_PAGE_TITLE}</Title>
          </Show>
          <Link
            rel="canonical"
            href={`https://www.myhubble.money/shop-by-category/${params.category}`}
          />
          <Meta name="og:type" content="product" />
          <Meta name="og:title" content={getMetadataTitle(routeData)} />
          <Show when={routeData()?.metadata}>
            <Meta
              name="keywords"
              content={`${routeData()?.metadata?.keywords}`}
            />
            <Meta
              name="description"
              content={`${routeData()?.metadata?.description}`}
            />
            <Meta
              name="og:description"
              content={`${routeData()?.metadata?.description}`}
            />
          </Show>
        </Show>

        <Show when={routeData()}>
          <Header
            headerImageUrl={routeData()?.clientConfig?.clientLogoUrl}
            showActions={!routeData()?.sdkMetaData?.isSDK}
            isLoggedIn={isLoggedIn}
            mode={routeData()?.mode ?? "hubble"}
            coins={getCoins}
            showCoins={() =>
              routeData()?.mode === "hubble" || routeData()?.mode === "rnr"
            }
            setRefreshCoins={setRefreshLatestBalance}
            clientId={routeData()?.clientId}
          />
        </Show>

        <Show
          when={
            routeData()?.categoryList &&
            routeData()?.categoryList != null &&
            !config.customizedSDKClients.limitedCategoryContent.includes(
              routeData()?.clientId!
            )
          }
        >
          <div
            class={`sticky w-full pb-3 pt-1 ${isClientAxis() ? "top-[4.5rem] sm:top-[6.5rem]" : "top-10 sm:top-[4.5rem]"}`}
            style={{
              "z-index": zIndexes.categoryStrip,
            }}
          >
            <div class="hidden sm:flex">
              <CategoryStrip
                categories={routeData()!.categoryList.data}
                scroll={false}
                border={true}
                selectedCategory={getCategoryKeyFromSlug(params.category)}
                maxCategoriesOnStrip={Math.min(
                  10,
                  routeData()!.categoryList.data.length
                )}
                occasions={occasionsData.occasions}
                recipients={recipientsData.recipients}
                showCategoryList={showCategoryList}
                setShowCategoryList={setShowCategoryList}
              ></CategoryStrip>
            </div>
            <div class="flex sm:hidden">
              <CategoryStrip
                categories={routeData()!.categoryList.data}
                selectedCategory={getCategoryKeyFromSlug(params.category)}
                scroll={true}
                border={false}
                occasions={occasionsData.occasions}
                recipients={recipientsData.recipients}
                showCategoryList={showCategoryList}
                setShowCategoryList={setShowCategoryList}
              ></CategoryStrip>
            </div>
          </div>
        </Show>

        <Show when={!isRouting()} fallback={<DottedLoader color="#999" />}>
          <div class="ml-auto mr-auto lg:w-[1136px]">
            <Show
              when={routeData()?.mode === "hubble" && routeData()?.brandList}
            >
              <GroupTextHeaderOccasion
                text={getHeaderTitleText(
                  routeData()?.brandList.data[0].products!
                )}
              />
            </Show>

            <Show when={routeData()?.mode === "hubble"}>
              <div class="border-y-[1px] border-y-gray-200">
                <TrustMakerStrip />
              </div>
            </Show>

            <div class="flex items-center px-4 pt-4 lg:px-0">
              <Show when={routeData()?.mode !== "hubble"}>
                <div class="hidden text-h4 text-basePrimaryDark lg:flex">
                  {getCategoryTitleFromSlug(params.category)}
                </div>
                <div class="mx-[6px] hidden h-1 w-1 rounded-full bg-baseSecondaryLight lg:flex"></div>
                <div class="text-bold lg:text-textNormal">
                  {routeData()?.brandList.data[0].products.length} brands
                </div>
                <div class="ml-4 hidden h-[1px] grow bg-baseTertiaryDark lg:flex"></div>
              </Show>
            </div>
            <div class="mb-16 w-full px-4 lg:px-0">
              <div class="noScrollbar mt-5 grid grid-cols-3 gap-6 gap-x-3 gap-y-6 overflow-auto md:grid-cols-6">
                <For each={routeData()?.brandList.data[0].products}>
                  {(brand) => (
                    <BrandCard
                      imageUrl={brand.voucherProduct.iconImageUrl}
                      name={brand.voucherProduct.title}
                      categoryName={brand.displayTags[0] || ""}
                      discountPercentage={
                        brand.voucherProduct.discountPercentage
                      }
                      brandKey={brand.brandKey}
                      showHubbleCoinReward={
                        brand.voucherProduct.rewardType === "POSTPAID"
                      }
                    />
                  )}
                </For>
              </div>
            </div>
          </div>
        </Show>
        <Spacer height={30}></Spacer>
        <Show when={routeData()?.mode === "rnr"}>
          <div class="absolute bottom-0 hidden w-full sm:flex">
            <HubbleBranding class="w-full py-4" />
          </div>
          <BrandingRow class="fixed bottom-0 left-0 right-0 items-center sm:hidden" />
        </Show>
        <Show when={routeData()?.mode === "hubble"}>
          <div class="flex justify-center">
            <div class="lg:px-32">
              <Footer />
            </div>
          </div>
        </Show>
      </div>
    </Suspense>
  );
}
